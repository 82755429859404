import { useState, useEffect } from "react";
import { apiGET } from "../utils/api";
import { checkTimes } from "../utils/general";

import '../css/Footer.css';
import ContactInfoBox from "./ContactInfoBox";

const Footer = () => {
    const [hours, setHours] = useState({
        'monday': {'open': '', 'close': ''}, 
        'tuesday': {'open': '', 'close': ''}, 
        'wednesday': {'open': '', 'close': ''}, 
        'thursday': {'open': '', 'close': ''}, 
        'friday': {'open': '', 'close': ''},
        'saturday': {'open': '', 'close': ''},
        'sunday': {'open': '', 'close': ''},
        'special': {}
    });

    // useEffect => sets the hours and contact info in the footer on page load 
    useEffect(() => {
        async function fetchHours() { 
            const hrs = await apiGET('/get-hours');
            setHours(hrs);
        }

        fetchHours();
    }, []);

    
    return (
        <div className='footer'>
            <div className='footer-col' id='footer-hours'>
                <h1>Weekly Hours</h1>
                <ul className='footer-content footer-hours-content'>
                    <li className="footer-hour-li">
                        <span>Monday </span> 
                        <p>{
                            hours && checkTimes(hours.monday['open'], hours.monday['close']) ? 
                                `${hours.monday['open']} to ${hours.monday['close']}`
                            : 'Closed' 
                        }</p>
                    </li>

                    <li className="footer-hour-li">
                        <span>Tuesday </span> 
                        <p>{
                            checkTimes(hours.tuesday['open'], hours.tuesday['close']) ? 
                                `${hours.tuesday['open']} to ${hours.tuesday['close']}`
                            : 'Closed' 
                        }</p>
                    </li>

                    <li className="footer-hour-li">
                        <span>Wednesday </span>
                        <p>{
                            checkTimes(hours.wednesday['open'], hours.wednesday['close']) ? 
                                `${hours.wednesday['open']} to ${hours.wednesday['close']}`
                            : 'Closed' 
                        }</p>
                    </li>

                    <li className="footer-hour-li">
                        <span>Thursday </span>
                        <p>{
                            checkTimes(hours.thursday['open'], hours.thursday['close']) ? 
                                `${hours.thursday['open']} to ${hours.thursday['close']}`
                            : 'Closed' 
                        }</p>
                    </li>

                    <li className="footer-hour-li">
                        <span>Friday </span>
                        <p>{
                            checkTimes(hours.friday['open'], hours.friday['close']) ? 
                                `${hours.friday['open']} to ${hours.friday['close']}`
                            : 'Closed' 
                        }</p>
                    </li>

                    <li className="footer-hour-li">
                        <span>Saturday </span>
                        <p>{
                            checkTimes(hours.saturday['open'], hours.saturday['close']) ? 
                                `${hours.saturday['open']} to ${hours.saturday['close']}`
                            : 'Closed' 
                        }</p>
                    </li>
                    
                    <li className="footer-hour-li">
                        <span>Sunday </span>
                            <p>{
                                checkTimes(hours.sunday['open'], hours.sunday['close']) ? 
                                    `${hours.sunday['open']} to ${hours.sunday['close']}`
                                : 'Closed' 
                        }</p>
                    </li>
                </ul>
            </div>

            <div className='footer-col' id='footer-contact'>
                <ContactInfoBox />
            </div>

            <div className='copyright' id='copyright'>&copy; Josie's Bakery {new Date().getFullYear()}. All rights reserved.</div>
        </div>
    );
}

export default Footer;
