
import '../css/PortalHeader.css';
import { Link } from 'react-router-dom';

const PortalHeader = ({selected}) => {
    
    return (
        <div className='portal-header'>
            <Link className={`portal-header-item ${selected === 'orders' ? 'selected' : ''}`} to='/portal'>Orders</Link>
            <Link className={`portal-header-item ${selected === 'sales' ? 'selected' : ''}`} to='/portal/sales'>Sales</Link>
            <Link className={`portal-header-item ${selected === 'menu' ? 'selected' : ''}`} to='/portal/menu'>Menu</Link>
        </div>
    )
}

export default PortalHeader;
