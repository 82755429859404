
import { useState, useEffect } from 'react';
import { BsPencilSquare, BsCheck, BsX } from "react-icons/bs";


import { getMenuItems, updateMenuItem } from '../utils/api';

import PortalHeader from "../components/PortalHeader";

import '../css/PortalMenuPage.css';


function PortalMenuPage () {
    const [menuItems, setMenuItems] = useState([]);
    
    const [editingCell, setEditingCell] = useState(null);
    const [editValue, setEditValue] = useState('');


    /**
     * @const handleEditClick 
     * @description Handles when an edit icon is clicked to update the states
     * @param { Number } itemId 
     * @param { String } column 
     */
    const handleEditClick = (itemId, column) => {
        console.log(`editing item ${itemId} at column ${column}`);

        // Set the editing cell state
        setEditingCell({ itemId, column });

        // Set the default as the current value
        setEditValue(menuItems.find(item => item.item_id === itemId)[column]);
    }


    const handleSave = async () => {
        const { itemId, column } = editingCell;

        // API call to update the DB
        console.log('making update DB API call...');
        console.log('itemId: ', itemId);
        console.log('column: ', column);
        console.log('editValue: ', editValue);
        
        const response = updateMenuItem(itemId, column, editValue);
        console.log('handleSave() got response: ', response);
        
        // Update local state 
        const idx = menuItems.findIndex(item => item.item_id === itemId);
        var tmp = menuItems[idx];
        
        tmp[column] = editValue;
        menuItems[idx] = tmp;

        // Exit edit mode
        setEditingCell(null);
        setEditValue('');
    }


    const handleCancel = () => {
        setEditingCell(null);
        setEditValue('');
    }

    // useEffect => fetches and sets the menu items on page load
    useEffect(() => {
        async function fetchMenuItems() {
            const items = await getMenuItems();
            setMenuItems(items);
            console.log('menu items:', items);
        }
        fetchMenuItems();
    }, []);

    return (
        <div className='portal-menu-page'>
            <PortalHeader selected='menu' />
            
            <div className='menu-config-container'>
                <table className='menu-items-table'>
                    <thead>
                        <tr>
                            <th>Item ID</th>
                            <th>Item Name</th>
                            <th>Item Price</th>
                            <th>Item Description</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.entries(menuItems).map(([idx, item]) => (
                                <tr id={`menu-item-row-${item.item_id}`} key={idx}>
                                    <td style={{textAlign: 'center'}}>{item.item_id}</td>
                                    
                                    <td>
                                        {
                                            editingCell?.itemId === item.item_id &&
                                            editingCell?.column === "item_name" ? (
                                                <div className="edit-cell">
                                                    <input
                                                        type="text"
                                                        value={editValue}
                                                        onChange={(e) => setEditValue(e.target.value)}
                                                    />
                                                    <BsCheck className="save-icon" onClick={handleSave} />
                                                    <BsX className="cancel-icon" onClick={handleCancel} />
                                                </div>
                                            ) : (
                                                <div>
                                                    {item.item_name}{" "}
                                                    <BsPencilSquare
                                                        className="pencil-icon"
                                                        onClick={() => handleEditClick(item.item_id, "item_name")}
                                                    />
                                                </div>
                                            )
                                        }
                                    </td>

                                    <td style={{textAlign: 'center'}}>$ {item.item_price} <BsPencilSquare className='pencil-icon' /></td>
                                    <td>{item.item_description} <BsPencilSquare className='pencil-icon' /></td>
                                    <td></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                
            </div>
        </div>
    )
}

export default PortalMenuPage;
