

/**
 * @const getCurrentDate
 * @returns { String } the current date as a string in YYYY-MM-DD format
 */
export const getCurrentDate = () => {
    const today = new Date();
    
    // Get the year, month, and day
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // NOTE: months are 0-based
    const day = String(today.getDate()).padStart(2, "0");
    
    return `${year}-${month}-${day}`;
};


/**
 * @const parseTime 
 * @param { String } timeStr time as a string in HH:MM AM/PM format  
 * @returns { Date } a date object with just the parsed out time
 */
export const parseTime = (timeStr) => {
    const [time, modifier] = timeStr.split(" ");
    let [hours, minutes] = time.split(":").map(Number);
  
    if (modifier === "PM" && hours !== 12) {
      hours += 12; // Convert PM to 24-hour format
    }
    if (modifier === "AM" && hours === 12) {
      hours = 0; // Handle midnight (12:00 AM)
    }
  
    return new Date(0, 0, 0, hours, minutes); // Create a Date object with the parsed time
};


/**
 * @function checkTimes
 * @description Simply checks if there is an open and close time given (i.e. neither is empty string)
 * @param { String } open 
 * @param { String } close 
 * @returns { bool }
 */
export function checkTimes(open, close) {
  return open.length > 0 && close.length > 0;
}


/**
 * @const isEmpty
 * @description Checks if the given object is empty
 * @param { Object } obj 
 * @returns { bool }
 */
export const isEmpty = (obj) => Object.keys(obj).length === 0;


/**
 * @function convertTo24Hour 
 * @description Converts a time string in the format "HH:MM AM/PM" to 24-hr time 
 * @param { String } time 
 * @returns { String } the time in 24-hr format as a string
 */
export function convertTo24Hour(time) {
  const [fullMatch, hour, minute, period] = time.match(/(\d+):(\d+)\s(AM|PM)/i);
  let adjustedHour = parseInt(hour, 10);

  if (fullMatch && period === "PM" && adjustedHour !== 12) {
      adjustedHour += 12;
  } else if (period === "AM" && adjustedHour === 12) {
      adjustedHour = 0;
  }

  return `${adjustedHour.toString().padStart(2, "0")}:${minute}`;
}

/**
 * @function isValidEmail
 * @description Validates if the given email address is in the correct format.
 * @param {string} email the email address to validate.
 * @returns {boolean} returns true if the email is valid, false otherwise.
 */
export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}


/**
 * @function isValidPhoneNumber
 * @description Validates if the given phone number is in an acceptable format.
 * @param {string} phoneNumber the phone number to validate.
 * @returns {boolean} returns true if the phone number is valid, false otherwise.
 */
export function isValidPhoneNumber(phoneNumber) {
  const phoneRegex = /^(?:\(\d{3}\)\s?|\d{3}[-.\s]?)?\d{3}[-.\s]?\d{4}$/;
  return phoneRegex.test(phoneNumber);
}


/**
 * @function isValidCreditCardNumber
 * @description Validates if the given credit card number is in a valid format and passes Luhn check.
 * @param { String } cardNumber the credit card number to validate.
 * @returns { boolean } returns true if the credit card number is valid, false otherwise.
 */
export function isValidCreditCardNumber(cardNumber) {
  const cardRegex = /^[0-9]{13,19}$/;
  
  if (!cardRegex.test(cardNumber)) {
      return false; // Early return if format doesn't match.
  }
  
  // Luhn algorithm to validate the card number
  let sum = 0;
  let shouldDouble = false;

  // Iterate over the digits in reverse
  for (let i = cardNumber.length - 1; i >= 0; i--) {
      let digit = parseInt(cardNumber.charAt(i), 10);
      
      if (shouldDouble) {
          digit *= 2;
          if (digit > 9) {
              digit -= 9; // Add the sum of digits (e.g., 16 -> 1 + 6 = 7)
          }
      }

      sum += digit;
      shouldDouble = !shouldDouble;
  }
  
  return sum % 10 === 0; // Valid if divisible by 10
}


/**
 * @function isValidExpiryDate
 * @description Validates if the given credit card expiry date is in the correct MM/YY format and is not expired.
 * @param { String } expiryDate the expiry date (MM/YY) to validate.
 * @returns { boolean } returns true if the expiry date is valid, false otherwise.
 */
export function isValidExpiryDate(expiryDate) {
  const expiryRegex = /^(0[1-9]|1[0-2])\/\d{2}$/;

  if (!expiryRegex.test(expiryDate)) {
      return false; // Invalid format (MM/YY)
  }

  const [month, year] = expiryDate.split('/');
  const expiryMonth = parseInt(month, 10);
  const expiryYear = parseInt(year, 10) + 2000; // Convert 2-digit year to 4-digit

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // Get current month (1-12)
  const currentYear = currentDate.getFullYear();

  // Check if the expiry year is in the future or the same year but the expiry month is greater
  return expiryYear > currentYear || (expiryYear === currentYear && expiryMonth >= currentMonth);
}


/**
 * @function isValidCVV
 * @description Validates if the given credit card CVV is in the correct 3-4 digit format.
 * @param { String } cvv the CVV to validate.
 * @returns { boolean } returns true if the CVV is valid, false otherwise.
 */
export function isValidCVV(cvv) {
  const cvvRegex = /^[0-9]{3,4}$/;
  return cvvRegex.test(cvv);
}


/**
 * @function reformatDate 
 * @description Takes in a date string and reformats it
 * @param { String } dateStr A date string
 * @returns { String } the date string in the format "Day of week, DD-MON-YYYY"
 */
export function reformatDate(dateStr) {
  // Parse the date string manually to avoid timezone issues
  const [year, month, day] = dateStr.split('-').map(Number);
  const date = new Date(year, month - 1, day); // Month is 0-based 

  // Format the date
  return new Intl.DateTimeFormat('en-US', {
    weekday: 'long',   // Day of the week
    day: 'numeric',    // Day of the month
    month: 'short',    // Short month name
    year: 'numeric'    // Year
  }).format(date);
}


/**
 * @const formatPhoneNumber
 * @description Formats a phone number (or partial phone number) to "(000) 000-0000"
 * @param { String } value 
 * @returns { String }
 */
export const formatPhoneNumber = (value) => {
  // Remove all non-numeric characters
  const digits = value.replace(/\D/g, "");

  // Format as "(000) 000-0000"
  if (digits.length === 0) {
    return "";
  } else if (digits.length <= 3) {
    return `(${digits}`;
  } else if (digits.length <= 6) {
    return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
  } else {
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
  }
};


/**
 * @function redirectToMainSite
 * @description Redirects to the main site (josiesbakery.com) in the same tab
 * @returns { null }
 */
export function redirectToMainSite() { 
  window.location.href = "https://josiesbakery.com";
}