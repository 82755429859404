import { useState, useEffect } from 'react';
import { apiGET } from '../utils/api';

 
const SiteTitle = () => {
    const [siteTitle, setSiteTitle] = useState("Josie's Bakery");

    // useEffect => sets the site title on page load.
    useEffect(() => {
        async function fetchSiteTitle() {
            const conf = await apiGET('/get-form-config');
            setSiteTitle(conf.formTitle);
        };

        fetchSiteTitle();
    }, []);

    return(
        <div className='site-title'>{siteTitle}</div>
    )
}

export default SiteTitle;